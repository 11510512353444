
import { defineComponent, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { WSSignalR } from "@/core/plugins/signalr";
import {
  ILanguage,
  LanguageCodes,
  LanguageTypes,
} from "@/core/types/LanguageTypes";

import Clipboard from "clipboard";
import JwtService from "@/core/services/JwtService";
import TenantGlobalInjectionKeys from "@/core/types/TenantGlobalInjectionKeys";

export default defineComponent({
  name: "kt-user-menu",

  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const user = store.state.AuthModule.user;

    const wsSignalR = inject(TenantGlobalInjectionKeys.WS_SIGNAL_R);

    let storageLanguage = localStorage.getItem("language");
    i18n.locale.value =
      LanguageCodes.all.find((lang) => lang === storageLanguage) ||
      LanguageCodes.enUS;

    let countries: ILanguage[];
    countries = LanguageTypes.all;
    const signOut = () => {
      wsSignalR?.disconnect();
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = (language: string) => {
      store.dispatch(Actions.SET_LANG, { language: language });
      i18n.locale.value = language;
    };

    const currentLanguage = (language: string) => {
      return i18n.locale.value === language;
    };

    const currentLanguageLocale = computed(() => {
      return (
        countries.find((x) => x.code === i18n.locale.value) ??
        LanguageTypes.enUS
      );
    });

    const copyToken = () => {
      Clipboard.copy(JwtService.getToken() as string);
    };

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLanguageLocale: currentLanguageLocale,
      countries,
      user,
      copyToken,
    };
  },
});
