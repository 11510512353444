export enum SalesTypes {
  Default = 0,
  Vietnam = 1,
  China = 2,
  Malaysia = 3,
  Wholesale = 4,
  Record = 5,
  SpecialVn = 6,
  SpecialCn = 7,
  SpecialMy = 8,
  Special = 9,
  KimTeam = 10,
}

export const salesTypeOptions = [
  {
    label: "Default",
    value: SalesTypes.Default,
    rebate: 999,
    alphaRebate: 999,
  },
  {
    label: "Vietnam (2.5/1.25)",
    value: SalesTypes.Vietnam,
    rebate: 250,
    alphaRebate: 125,
  },
  {
    label: "China (2.5/1.5)",
    value: SalesTypes.China,
    rebate: 250,
    alphaRebate: 150,
  },
  {
    label: "Malaysia (2.5/1.5)",
    value: SalesTypes.Malaysia,
    rebate: 250,
    alphaRebate: 150,
  },
  {
    label: "Wholesale (4/2)",
    value: SalesTypes.Wholesale,
    rebate: 400,
    alphaRebate: 200,
  },
  {
    label: "Record",
    value: SalesTypes.Record,
    rebate: 999,
    alphaRebate: 999,
  },
  {
    label: "Sepcial Vietnam",
    value: SalesTypes.SpecialVn,
    rebate: 999,
    alphaRebate: 999,
  },
  {
    label: "Sepcial China",
    value: SalesTypes.SpecialCn,
    rebate: 999,
    alphaRebate: 999,
  },
  {
    label: "Sepcial Malaysia (2.5/2.25)",
    value: SalesTypes.SpecialMy,
    rebate: 250,
    alphaRebate: 225,
  },
  {
    label: "Sepcial Setting",
    value: SalesTypes.Special,
    rebate: 999,
    alphaRebate: 999,
  },
  {
    label: "Kim Team (2.8/1.4)",
    value: SalesTypes.KimTeam,
    rebate: 280,
    alphaRebate: 140,
  },
];
