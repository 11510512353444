import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c79bee56"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semobold py-4 fs-6",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-3" }
const _hoisted_3 = { class: "menu-content d-flex align-items-center px-3" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "fw-bold d-flex align-items-center fs-5" }
const _hoisted_6 = {
  href: "#",
  class: "fw-semobold text-muted text-hover-primary fs-7"
}
const _hoisted_7 = {
  class: "menu-item px-5",
  "data-kt-menu-trigger": "hover",
  "data-kt-menu-placement": "left-start",
  "data-kt-menu-flip": "center, top"
}
const _hoisted_8 = { class: "menu-title position-relative" }
const _hoisted_9 = { class: "fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "menu-sub menu-sub-dropdown w-225px py-4" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "symbol symbol-20px me-4" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "menu-item px-5 my-1" }
const _hoisted_16 = {
  key: 0,
  class: "menu-item px-5 my-1"
}
const _hoisted_17 = { class: "menu-item px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.user)
          ? (_openBlock(), _createBlock(_component_UserAvatar, {
              key: 0,
              avatar: _ctx.user?.avatar,
              name: _ctx.user?.name,
              size: "50px",
              class: "me-3",
              side: "client"
            }, null, 8, ["avatar", "name"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.user.name), 1),
          _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.user.email), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_router_link, {
        to: "/profile",
        class: "menu-link px-5"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_8, [
            _createTextVNode(_toDisplayString(_ctx.$t("fields.language")) + " ", 1),
            _createElementVNode("span", _hoisted_9, [
              _createTextVNode(_toDisplayString(_ctx.currentLanguageLocale.name) + " ", 1),
              _createElementVNode("img", {
                class: "w-15px h-15px rounded-1 ms-2",
                src: _ctx.currentLanguageLocale.flag,
                alt: "BCR Pro"
              }, null, 8, _hoisted_10)
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (lang, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "menu-item px-3",
            key: index
          }, [
            _createElementVNode("a", {
              onClick: ($event: any) => (_ctx.setLang(lang.code)),
              href: "#",
              class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage(lang.code) }])
            }, [
              _createElementVNode("span", _hoisted_13, [
                _createElementVNode("img", {
                  class: "rounded-1",
                  src: lang.flag,
                  alt: "BCR Pro"
                }, null, 8, _hoisted_14)
              ]),
              _createTextVNode(" " + _toDisplayString(lang.name ?? "Name"), 1)
            ], 10, _hoisted_12)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createVNode(_component_router_link, {
        to: "/profile",
        class: "menu-link px-5"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("title.accountSettings")), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.$can('SuperAdmin') && false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("a", {
            href: "#",
            class: "menu-link px-5",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.copyToken && _ctx.copyToken(...args)), ["prevent"]))
          }, " Copy UserToken ")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_17, [
      _createVNode(_component_router_link, {
        to: "/sign-out",
        class: "menu-link px-5"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("action.signOut")), 1)
        ]),
        _: 1
      })
    ])
  ]))
}